<template>
  <div>
    <el-col :span="24">
      <!-- main wrapper -->

      <div class="right-panel-header">
        <span>Today’s Micro Lesson</span>
        <span class="tips-library">
          {{ answered }} / {{ _micro_lessons.length }}
        </span>
      </div>
      <FirstBlockRight @change="change(count)"></FirstBlockRight>

      <div class="right-panel-header">
        <span>Tips</span>
        <span class="tips-library">Tips Library</span>
      </div>
      <SecondBlockRight></SecondBlockRight>
      <ThirdBlock></ThirdBlock>
    </el-col>
  </div>
</template>

<script>
import FirstBlockRight from './right-panel-content/FirstBlockRight.vue'
import SecondBlockRight from './right-panel-content/SecondBlockRight.vue'
import ThirdBlock from './left-panel-content/ThirdBlock.vue'
export default {
  name: 'RightPanel',
  components: {
    FirstBlockRight,
    SecondBlockRight,
    ThirdBlock,
  },
  data() {
    return {
      count: 0,
      answered: 0,
    }
  },
  computed: {
    _micro_lessons() {
      return this.$store.getters._micro_lessons
    },
  },
  methods: {
    change(value) {
      let ans = this.$cookies.get('answered')
      // let ans = JSON.parse(localStorage.getItem('answered'))
      this.answered = ans.length
      this.count = value
    },
  },
}
</script>

<style scoped>
.right-panel-header {
  display: inline-block;
  width: 100%;
  padding: 10px;
}
.tips-library {
  float: right;
  margin-right: 20px;
  color: #a2b0d5;
}
</style>
