<template>
  <div>
    <el-col :span="24">
      <el-row :gutter="20">
        <el-col :span="24" style="margin-top: 15;">
          <div
            v-if="items"
            class="first-block-color-blocks"
            v-bind:style="{
              'background-image': 'url(' + bg + ')',
            }"
            style="border: 1px solid #403b62;"
          >
            <div v-for="(item, i) in items" :key="i" style="width: 14%;">
              <div :class="getLevelBlockClass(item)">
                <template v-if="item.status !== 'locked'">
                  Level {{ item.id }}
                </template>
                <template v-else>
                  <i class="el-icon-lock"></i>
                </template>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </div>
</template>

<script>
export default {
  name: 'LevelBlock',
  data() {
    return {
      bg: require('../../../assets/images/levelblock-bg.png'),
      items: [
        {
          id: 1,
          status: 'locked',
        },
        {
          id: 2,
          status: 'locked',
        },
        {
          id: 3,
          status: 'locked',
        },
        {
          id: 4,
          status: 'locked',
        },
        {
          id: 5,
          status: 'locked',
        },
        {
          id: 6,
          status: 'locked',
        },
        {
          id: 7,
          status: 'locked',
        },
      ],
    }
  },
  mounted() {
    this.getLevel()
  },
  computed: {
    _data_option() {
      return this.$store.getters._data_option
    },
  },
  methods: {
    /* eslint-disable */

    getLevelBlockClass(item) {
      if (item.status === 'inactive') {
        return 'level-block'
      } else if (item.status === 'active') {
        return 'level-block active'
      } else {
        return 'level-block locked'
      }
    },
    getLevel() {
      this.items.forEach((data, i) => {
        data.status =
          this._data_option.level === data.id
            ? 'active'
            : this._data_option.level < data.id
            ? 'inactive'
            : 'locked'
      })
    },
  },
}
</script>

<style scoped>
.level-block {
  padding: 10px 7px;
  border: 1px solid rgb(180 180 180 / 17%);
  background-color: rgb(180 180 180 / 17%);
  font-size: 12px;
  font-weight: 600;
}

.first-block-color-blocks {
  padding: 20px;
  height: 54px;
  /* overflow: hidden; */
  width: auto !important;
  background-size: cover;
  background-position: center;
}

.level-block.active {
  padding: 20px 10px 20px 5px;
  border: 1px solid #abd857;
}
.level-block {
  width: 76%;
  text-align: center;
}
</style>
