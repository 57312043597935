<template>
  <div>
    <!-- main wrapper -->
    <el-col :span="24">
      <!-- 1st block -->
      <div class="right-panel-header" style="padding: 10px;">
        <span>Your Current Level</span>
      </div>
      <LevelBlock v-if="data.customer_id"></LevelBlock>
      <FirstBlock></FirstBlock>
      <SecondBlock></SecondBlock>
      <!-- <ThirdBlock></ThirdBlock> -->
    </el-col>
  </div>
</template>

<script>
import LevelBlock from './left-panel-content/LevelBlock.vue'
import FirstBlock from './left-panel-content/FirstBlock.vue'
import SecondBlock from './left-panel-content/SecondBlock.vue'
// import ThirdBlock from './left-panel-content/ThirdBlock.vue'
export default {
  name: 'LeftPanel',
  components: {
    FirstBlock,
    SecondBlock,
    LevelBlock,
    // ThirdBlock,
  },
  props: {
    data: {
      type: Object,
    },
  },
}
</script>

<style scoped></style>
