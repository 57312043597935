var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-col',{attrs:{"span":24}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticStyle:{"margin-top":"20px"}},[_c('span',[_vm._v(" Progress Reaching the Next Level "),_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"color":"#5ce6e6"}})])])]),_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"right-box-container",staticStyle:{"display":"inline-block","width":"-webkit-fill-available","padding-bottom":"3%"}},[_c('span',{staticStyle:{"display":"block","margin-bottom":"20px","font-weight":"600","font-size":"14px"}},[_vm._v(" Coaching ")]),_c('el-col',{staticClass:"remove-padding",attrs:{"span":12}},[_c('div',[_c('span',{staticClass:"fist-block-header fist-block-header-left"},[_c('strong',[_vm._v(_vm._s(_vm._data_option.coaching_sessions_booked))]),_vm._v(" / 3 ")])]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',{staticClass:"total-points"},[_vm._v(" Booked "),_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"color":"#5ce6e6"}})]),_c('el-col',{staticStyle:{"padding":"0px !important"},attrs:{"span":24}},[_c('div',{staticClass:"second-block-progress",class:_vm._data_option.coaching_sessions_booked >= 1
                      ? 'active'
                      : '--'}),_c('div',{staticClass:"second-block-progress",class:_vm._data_option.coaching_sessions_booked >= 2
                      ? 'active'
                      : '--'}),_c('div',{staticClass:"second-block-progress",class:_vm._data_option.coaching_sessions_booked >= 3
                      ? 'active'
                      : '--'})])],1)]),_c('el-col',{attrs:{"span":12}},[_c('div',[_c('span',{staticClass:"fist-block-header"},[_c('strong',[_vm._v(" "+_vm._s(_vm._data_option.coaching_sessions_attended)+" ")]),_vm._v(" / 3 ")])]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',{staticClass:"total-points"},[_vm._v(" Attended "),_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"color":"#5ce6e6"}})]),_c('el-col',{staticStyle:{"padding":"0px !important"},attrs:{"span":24}},[_c('div',{staticClass:"second-block-progress",class:_vm._data_option.coaching_sessions_attended >= 1
                      ? 'active'
                      : '--'}),_c('div',{staticClass:"second-block-progress",class:_vm._data_option.coaching_sessions_attended >= 2
                      ? 'active'
                      : '--'}),_c('div',{staticClass:"second-block-progress",class:_vm._data_option.coaching_sessions_attended >= 3
                      ? 'active'
                      : '--'})])],1)])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }