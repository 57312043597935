<template>
  <div>
    <el-col :span="24" style="position: relative;">
      <div
        v-if="_micro_lessons.length > 0 && show"
        class="right-box-container"
        style="position: relative; display: block !important;"
      >
        <span>{{ _micro_lessons[questions_count].question.text }}</span>

        <el-col :span="24" style="display: inline-block; margin-top: 20px;">
          <el-col :span="24" syle="padding-left: 0px !important;">
            <el-radio-group v-model="answer" size="small" class="radio-option">
              <el-radio
                v-for="(choice, i) in _micro_lessons[questions_count].question
                  .choices"
                :key="i"
                :label="choice.key"
              >
                {{ choice.key }}. {{ choice.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>
        <div>
          <el-button
            @click="handleAnswer()"
            type="success"
            :disabled="!answer"
            style="width: 100%; margin-top: 20px;"
          >
            Answer
          </el-button>
        </div>
      </div>

      <div
        v-if="message"
        class="pop-up-answer"
        @click="closeMessage()"
        style="cursor: pointer;"
      >
        <span class="answer-text" v-html="message"></span>
      </div>

      <div
        v-if="_micro_lessons.length === 0 || !show"
        class="right-box-container"
        style="position: relative; display: block !important;"
      >
        <span></span>
        <div style="padding: 11%; text-align: center; color: grey;">
          No available question
        </div>
      </div>
    </el-col>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'FirstBlockRight',
  data() {
    return {
      chart_a: require('../../../assets/images/chart-a.jpg'),
      chart_b: require('../../../assets/images/chart-b.jpg'),
      chart_c: require('../../../assets/images/chart-c.jpg'),
      answer: '',
      message: '',
      questions_count: 0,
      answered: 0,
      count: 0,
      show: true,
    }
  },
  computed: {
    _micro_lessons() {
      let micro_lessons = this.$store.getters._micro_lessons
      let new_arr = []
      let new_lessons = []
      let customer_id = this.$store.getters._data_option.customer_id

      // if (JSON.parse(localStorage.getItem('answered'))) {
      if (this.$cookies.get('answered')) {
        // if (localStorage.getItem('customer_id') === customer_id) {
        new_arr = this.$cookies.get('answered')

        micro_lessons.forEach((data, i) => {
          if (!new_arr.includes(data.question_id)) {
            new_lessons.push(data)
          }
        })
        // }
      } else {
        new_lessons = micro_lessons
      }

      return new_lessons
    },
  },
  methods: {
    handleAnswer() {
      if (
        this.answer ===
        this._micro_lessons[this.questions_count].question.answer_keys[0]
      ) {
        let answered = []
        if (this.$cookies.get('answered')) {
          // if (localStorage.getItem('answered')) {
          answered = this.$cookies.get('answered')
          // answered = JSON.parse(localStorage.getItem('answered'))
          answered.push(this._micro_lessons[this.questions_count].question_id)
          this.$cookies.remove('answered')
          this.$cookies.set('answered', answered)
          // localStorage.setItem('answered', JSON.stringify(answered))

          this.count = this._micro_lessons.length - answered.length
          this.$emit('change', this.count)

          if (answered.length >= this._micro_lessons.length) {
            this.show = false
          }
        } else {
          answered.push(this._micro_lessons[this.questions_count].question_id)
          this.$cookies.set('answered', answered)

          // localStorage.setItem('answered', JSON.stringify(answered))
          this.count = this._micro_lessons.length - answered.length

          if (answered.length >= this._micro_lessons.length) {
            this.show = false
          }

          this.$emit('change', this.count)
          // console.log(localStorage.getItem('answered'))
        }

        this.message =
          '<div style="color:white;  font-size: 20px;font-weight: 500;">Congratulations, you got the correct answer!</div>'

        // call api

        // const date = new Date() // Or any Date('YYYY-MM-DD')
        // const unixTimestamp = Math.floor(date.getTime() / 1000)

        var date = new Date()
        var now_utc = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds(),
        )

        const unixTimestamp = Math.floor(new Date(now_utc).getTime() / 1000)

        let params = {
          id:
            this._micro_lessons[this.questions_count].id +
            '_' +
            this.$cookies.get('customer_id'),
          object: 'micro_lessons',
          customer_id: this.$cookies.get('customer_id'),
          microlesson_id: this._micro_lessons[this.questions_count].id,
          question_id: this._micro_lessons[this.questions_count].question_id,
          customer_programme_id: this._micro_lessons[this.questions_count]
            .customer_programme_id,
          created_at: unixTimestamp,
          updated_at: unixTimestamp,
        }
        this.$store.dispatch('getEvents', params)
        this.$store.dispatch('updateData').then((response) => {})

        if (this.questions_count !== this._micro_lessons.length - 1) {
          this.questions_count = this.questions_count + 1
          this.answer = ''
        }
        // this.$store.dispatch('getData').then((response) => {
        // if (response.status === 200) {
        //   this.data = response.data
        // }
        // })
      } else {
        this.message =
          '<div style="color:#ec4141;  font-size: 20px;font-weight: 500;">Sorry! Wrong answer</div>'
      }
    },
    closeMessage() {
      this.message = ''
    },
  },
}
</script>

<style scoped>
label.el-radio {
  display: block;
}
</style>
