<template>
  <div>
    <el-col :span="24">
      <div class="right-carousel-wrapper">
        <div
          class="right-box-container"
          style="padding-bottom: 0px !important;"
        >
          <el-carousel
            indicator-position="outside"
            height="150px"
            :autoplay="false"
          >
            <el-carousel-item v-for="(item, i) in contents" :key="i">
              <div>
                <div>{{ item.title }}</div>
                <div
                  style="
                    color: #5ce6e6;
                    font-size: 16px;
                    font-weight: 400;
                    position: absolute;
                    bottom: 40px;
                  "
                >
                  {{ item.sub }}
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </el-col>
  </div>
</template>

<script>
export default {
  name: 'SecondBlockRight',
  data() {
    return {
      contents: [
        {
          title:
            'Unlock valuable insights with our coaching sessions! They boost your points and help you trade smarter.',
          sub: 'Book your coaching session',
        },
        {
          title: 'lorem ipsum',
          sub: 'Book your coaching session tes',
        },
      ],
    }
  },
}
</script>

<style scoped></style>
