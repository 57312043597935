<template>
  <div>
    <el-col :span="24" style="margin-top: 30px;">
      <el-col :span="12" class="remove-padding">
        <div>
          <i class="el-icon-circle-check" style="color: #abd857;"></i>
          <span style="font-size: 18px; font-weight: 400;">
            Success
          </span>
        </div>
        <div class="sub-content-caption">
          You've earned the maximum points for logging in this week!
        </div>
      </el-col>
      <el-col :span="12" class="remove-padding">
        <div>
          <i class="el-icon-circle-close" style="color: #ff6c6c;"></i>
          <span style="font-size: 18px; font-weight: 400;">
            Opportunity
          </span>
        </div>
        <div class="sub-content-caption">
          You haven't booked any coaching sessions this week.
        </div>
      </el-col>
    </el-col>
  </div>
</template>

<script>
export default {
  name: 'ThirdBlock',
}
</script>

<style scoped>
.sub-content-caption {
  padding: 15px 20px 20px 0;
  font-size: 12px;
  color: #a2b0d5;
}
</style>
