<template>
  <div>
    <el-col :span="24">
      <el-row :gutter="20">
        <el-col :span="24">
          <div style="margin-top: 20px;">
            <span>
              Progress Reaching the Next Level
              <i class="el-icon-warning-outline" style="color: #5ce6e6;"></i>
            </span>
          </div>
        </el-col>

        <el-col :span="24">
          <div
            class="right-box-container"
            style="
              display: inline-block;
              width: -webkit-fill-available;
              padding-bottom: 3%;
            "
          >
            <span
              style="
                display: block;
                margin-bottom: 20px;
                font-weight: 600;
                font-size: 14px;
              "
            >
              Coaching
            </span>
            <el-col :span="12" class="remove-padding">
              <div>
                <span class="fist-block-header fist-block-header-left">
                  <strong>{{ _data_option.coaching_sessions_booked }}</strong>
                  / 3
                </span>
              </div>
              <div style="margin-top: 10px;">
                <span class="total-points">
                  Booked
                  <i
                    class="el-icon-warning-outline"
                    style="color: #5ce6e6;"
                  ></i>
                </span>
                <el-col :span="24" style="padding: 0px !important;">
                  <div
                    class="second-block-progress"
                    :class="
                      _data_option.coaching_sessions_booked >= 1
                        ? 'active'
                        : '--'
                    "
                  ></div>
                  <div
                    class="second-block-progress"
                    :class="
                      _data_option.coaching_sessions_booked >= 2
                        ? 'active'
                        : '--'
                    "
                  ></div>
                  <div
                    class="second-block-progress"
                    :class="
                      _data_option.coaching_sessions_booked >= 3
                        ? 'active'
                        : '--'
                    "
                  ></div>
                </el-col>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <span class="fist-block-header">
                  <strong>
                    {{ _data_option.coaching_sessions_attended }}
                  </strong>
                  / 3
                </span>
              </div>
              <div style="margin-top: 10px;">
                <span class="total-points">
                  Attended
                  <i
                    class="el-icon-warning-outline"
                    style="color: #5ce6e6;"
                  ></i>
                </span>

                <el-col :span="24" style="padding: 0px !important;">
                  <div
                    class="second-block-progress"
                    :class="
                      _data_option.coaching_sessions_attended >= 1
                        ? 'active'
                        : '--'
                    "
                  ></div>
                  <div
                    class="second-block-progress"
                    :class="
                      _data_option.coaching_sessions_attended >= 2
                        ? 'active'
                        : '--'
                    "
                  ></div>
                  <div
                    class="second-block-progress"
                    :class="
                      _data_option.coaching_sessions_attended >= 3
                        ? 'active'
                        : '--'
                    "
                  ></div>
                </el-col>

                <!--<div class="dotted-line-wrapper">
                  <div
                    v-for="(item, i) in 1"
                    :key="i"
                    class="dotted-line"
                  ></div>
                </div> -->
              </div>
            </el-col>
          </div>
        </el-col>

        <!-- <el-col :span="24" style="margin-top: 15;">
          <div class="first-block-color-blocks">
            <div class="green"></div>
            <div class="orange"></div>
            <div class="red"></div>
            <div class="blue"></div>
          </div>
        </el-col> -->
      </el-row>
    </el-col>
  </div>
</template>

<script>
export default {
  name: 'FirstBlock',
  computed: {
    _data_option() {
      return this.$store.getters._data_option
    },
  },
}
</script>

<style scoped>
.second-block-progress {
  /**  width: 48% !important; */
  width: 31% !important;
}
.dotted-line {
  width: 100%;
  background: #2a395d;
}
.fist-block-header {
  display: block;
}
.total-points {
  font-weight: 600;
  font-size: 14px;
  color: #a2b0d5;
}
.fist-block-header-left {
  background: -webkit-linear-gradient(180deg, #abd857 2.5%, #6c9224 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.remove-padding {
  padding-left: 0px !important;
}
</style>
