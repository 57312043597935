<template>
  <div class="row full-height">
    <el-row>
      <el-col :span="24" class="">
        <el-col :span="5" class="left-panel">
          <el-tabs type="card" @tab-click="handleClick">
            <el-tab-pane>
              <span slot="label">
                <i class="el-icon-chat-square"></i>
                General Chat
              </span>
              General Chat
            </el-tab-pane>

            <el-tab-pane>
              <span slot="label">
                <i class="el-icon-takeaway-box"></i>
                My Gifts(1)
              </span>
              My Gifts(1)
            </el-tab-pane>
          </el-tabs>
        </el-col>

        <el-col :span="19" class="right-panel">
          <span class="right-header">Your Progress This Week</span>
          <span class="close-custom-icon"><i class="el-icon-close"></i></span>
          <el-col
            :span="24"
            style="padding-top: 30px; overflow: scroll; max-height: 100vh;"
          >
            <el-row :gutter="10">
              <el-col :span="12">
                <leftPanel :data="data"></leftPanel>
              </el-col>
              <el-col :span="12" style="border-left: 1px solid #2a395d;">
                <RightPanel></RightPanel>
              </el-col>
            </el-row>
          </el-col>
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable */
import leftPanel from './right-content/LeftPanel.vue'
import RightPanel from './right-content/RightPanel.vue'
export default {
  name: 'IndexVue',
  components: {
    leftPanel,
    RightPanel,
  },
  data() {
    return {
      loading: false,
      activeName: 'first',
      data: {},
    }
  },
  computed: {},
  created() {
    this.getData()
  },
  destroyed() {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    getData() {
      this.$store.dispatch('getData').then((response) => {
        if (response.status === 200) {
          this.data = response.data
        }
      })
    },
  },
}
</script>

<style scoped>
.close-custom-icon {
  float: right;
  margin-right: 20px;
  color: #a2b0d5;
}
.close-custom-icon i {
  font-size: 20px;
  font-weight: 600;
}
</style>
