<template>
  <div>
    <el-col :span="24">
      <div
        style="
          margin-top: 5px;
          width: -webkit-fill-available;
          padding-bottom: 35px;
          margin-bottom: 100px;
        "
        class="right-box-container"
      >
        <!-- <div class="dots green"></div> -->
        <span
          style="
            font-weight: 600;
            font-size: 14px;
            padding-bottom: 12px;
            display: inline-block;
          "
        >
          Points
          <!--          <i class="el-icon-warning-outline" style="color: #5ce6e6;"></i> -->
        </span>

        <span
          style="
            font-weight: 600;
            font-size: 14px;
            padding-bottom: 12px;
            float: right;
            display: inline-block;
          "
        >
          Total Points
          <!--          <i class="el-icon-warning-outline" style="color: #5ce6e6;"></i> -->
        </span>

        <div style="width: 80%; display: inline-block;">
          <span class="fist-block-header">
            <strong class="fist-block-header-left">
              {{ _data_option.total_points_weekly }}
            </strong>
            / 170
          </span>
        </div>

        <div
          style="
            float: right;
            display: inline-block;
            width: 20%;
            text-align: right;
          "
        >
          <span class="fist-block-header">
            <strong class="fist-block-header-left">
              {{ _data_option.total_points }}
            </strong>
          </span>
        </div>

        <div class="total-points">
          Total points collected this week
          <i class="el-icon-warning-outline" style="color: #5ce6e6;"></i>
        </div>
        <el-col :span="24">
          <el-progress
            :percentage="getPercentage(_data_option.total_points_weekly)"
            :show-text="false"
            :define-back-color="'#2A395D'"
            status="success"
            style="margin-top: 12px;"
          ></el-progress>
        </el-col>

        <el-col :span="24" style="padding: 0px;">
          <div style="margin-top: 20px;">
            <!-- <div class="dots orange"></div> -->
            <span>
              Trades
              <i class="el-icon-warning-outline" style="color: #5ce6e6;"></i>
            </span>

            <div style="font-size: 12px; color: #a2b0d5;">
              This week’s points:
              {{ _data_option.total_trade_points_weekly }}/30
            </div>
            <el-col :span="24">
              <div
                class="third-block-progress"
                :class="
                  _data_option.total_trade_points_weekly >= 10 ? 'active' : '--'
                "
              ></div>
              <div
                class="third-block-progress"
                :class="
                  _data_option.total_trade_points_weekly >= 20 ? 'active' : '--'
                "
              ></div>
              <div
                class="third-block-progress"
                :class="
                  _data_option.total_trade_points_weekly >= 30 ? 'active' : '--'
                "
              ></div>
            </el-col>
          </div>
        </el-col>

        <el-col :span="24" style="padding: 0px;">
          <div style="margin-top: 20px;">
            <!-- <div class="dots red"></div> -->
            <span>
              Micro Lessons
              <i class="el-icon-warning-outline" style="color: #5ce6e6;"></i>
            </span>

            <div style="font-size: 12px; color: #a2b0d5;">
              This week’s points:
              {{ _data_option.total_micro_lesson_points_weekly }}/70
            </div>
            <el-col :span="24">
              <div
                class="fourth-block-progress"
                :class="
                  _data_option.total_micro_lesson_points_weekly >= 10
                    ? 'active'
                    : '--'
                "
              ></div>
              <div
                class="fourth-block-progress"
                :class="
                  _data_option.total_micro_lesson_points_weekly >= 20
                    ? 'active'
                    : '--'
                "
              ></div>
              <div
                class="fourth-block-progress"
                :class="
                  _data_option.total_micro_lesson_points_weekly >= 30
                    ? 'active'
                    : '--'
                "
              ></div>
              <div
                class="fourth-block-progress"
                :class="
                  _data_option.total_micro_lesson_points_weekly >= 40
                    ? 'active'
                    : '--'
                "
              ></div>
              <div
                class="fourth-block-progress"
                :class="
                  _data_option.total_micro_lesson_points_weekly >= 50
                    ? 'active'
                    : '--'
                "
              ></div>
              <div
                class="fourth-block-progress"
                :class="
                  _data_option.total_micro_lesson_points_weekly >= 60
                    ? 'active'
                    : '--'
                "
              ></div>
              <div
                class="fourth-block-progress"
                :class="
                  _data_option.total_micro_lesson_points_weekly >= 70
                    ? 'active'
                    : '--'
                "
              ></div>
            </el-col>
          </div>
        </el-col>

        <el-col :span="24" style="padding: 0px;">
          <div style="margin-top: 20px;">
            <!-- <div class="dots blue"></div> -->
            <span>
              Login counter
              <i class="el-icon-warning-outline" style="color: #5ce6e6;"></i>
            </span>

            <div style="font-size: 12px; color: #a2b0d5; margin-bottom: 10px;">
              <span>
                This week’s points:
                {{ _data_option.total_login_points_weekly }}/70
              </span>

              <span style="float: right;">
                You are currently on
                {{ getCurrentDaySteak(_data_option.total_login_points_weekly) }}
                day streak!
              </span>
            </div>

            <el-col :span="24">
              <div
                class="fifth-block-progress"
                :class="
                  _data_option.total_login_points_weekly >= 10 ? 'active' : '--'
                "
              >
                Mo
              </div>
              <div
                class="fifth-block-progress"
                :class="
                  _data_option.total_login_points_weekly >= 20 ? 'active' : '--'
                "
              >
                Tu
              </div>
              <div
                class="fifth-block-progress"
                :class="
                  _data_option.total_login_points_weekly >= 30 ? 'active' : '--'
                "
              >
                We
              </div>
              <div
                class="fifth-block-progress"
                :class="
                  _data_option.total_login_points_weekly >= 40 ? 'active' : '--'
                "
              >
                Th
              </div>
              <div
                class="fifth-block-progress"
                :class="
                  _data_option.total_login_points_weekly >= 50 ? 'active' : '--'
                "
              >
                Fr
              </div>
              <div
                class="fifth-block-progress"
                :class="
                  _data_option.total_login_points_weekly >= 60 ? 'active' : '--'
                "
              >
                Sa
              </div>
              <div
                class="fifth-block-progress"
                :class="
                  _data_option.total_login_points_weekly >= 70 ? 'active' : '--'
                "
              >
                Su
              </div>
            </el-col>
          </div>
        </el-col>
      </div>
    </el-col>
  </div>
</template>

<script>
export default {
  name: 'SecondBlock',
  data() {
    return {
      key: 'z',
    }
  },
  computed: {
    _data_option() {
      return this.$store.getters._data_option
    },
  },
  methods: {
    getPercentage(value) {
      let val = 0
      if (value) {
        val = Math.ceil((value / 170) * 100)
      }
      return val
    },
    getCurrentDaySteak(value) {
      switch (value) {
        case 10:
          return 1
        case 20:
          return 2
        case 30:
          return 3
        case 40:
          return 4
        case 50:
          return 5
        case 60:
          return 6
        case 70:
          return 7
      }
    },
  },
}
</script>

<style scoped>
.third-block-progress {
  width: 32.3%;
}

.fourth-block-progress {
  font-size: 12px;
  color: #a2b0d5;
}
.fifth-block-progress {
  font-size: 12px;
  color: #a2b0d5;
  line-height: 31px;
}

.total-points {
  font-weight: 600;
  font-size: 14px;
  color: #a2b0d5;
}

.fist-block-header-left {
  background: -webkit-linear-gradient(180deg, #abd857 2.5%, #6c9224 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
