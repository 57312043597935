import { render, staticRenderFns } from "./SecondBlockRight.vue?vue&type=template&id=abeaa0aa&scoped=true"
import script from "./SecondBlockRight.vue?vue&type=script&lang=js"
export * from "./SecondBlockRight.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abeaa0aa",
  null
  
)

export default component.exports