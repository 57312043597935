import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);
/* eslint-disable */
const store = new Vuex.Store({
	namespaced: true,
	state: {
		micro_lessons: [],
		data_option: {},
		dashboard_data: {},
	},
	mutations: {
		GET_MICROLESSONS: (state, payload) => {
			state.micro_lessons = payload;
		},
		GET_DATA: (state, payload) => {
			state.data_option = payload;
			$cookies.set(
				'micro_lessons',
				payload
			);

			$cookies.set(
				'customer_id',
				payload.customer_id
			);
		},
		UPDATE_MICROLESSON_BARS: (
			state,
			payload
		) => {
			state.data_option.total_micro_lesson_points_weekly =
				state.data_option
					.total_micro_lesson_points_weekly +
				10;

			state.data_option.total_points_weekly =
				state.data_option
					.total_points_weekly + 10;
		},

		UPDATE_MICROLESSONS: (state, payload) => {
			state.micro_lessons = payload;
			// localStorage.removeItem(
			// 	'micro_lessons'
			// );
			$cookies.remove('micro_lessons');
			$cookies.set(
				'micro_lessons',
				state.micro_lessons
			);
			// localStorage.setItem(
			// 	'micro_lessons',
			// 	JSON.stringify(
			// 		state.micro_lessons
			// 	)
			// );
		},
	},
	actions: {
		async getEvents(
			{ commit, dispatch },
			params
		) {
			var FormData = require('form-data');
			var data = new FormData();

			// process.env.VUE_APP_USERNAME
			// process.env.VUE_APP_PASSWORD
			// process.env.VUE_APP_MICRO_LESSON_API

			data.append(
				'username',
				process.env.VUE_APP_USERNAME
			);
			data.append(
				'password',
				process.env.VUE_APP_PASSWORD
			);

			var config = {
				method: 'post',
				url: process.env.VUE_APP_AUTH_API,

				data: data,
			};

			axios(config)
				.then(function (response) {
					// let token =

					let pload = {
						token: response.data
							.access_token,
						params: params,
					};
					// dispatch.postEvent(token, params);
					dispatch('postEvent', pload);
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		async postEvent(
			{ commit, state },
			params
		) {
			return new Promise(
				(resolve, reject) => {
					let payload = params.params;

					var data = JSON.stringify({
						timestamp:
							payload.created_at,
						type: 'microlesson.completed',
						data: payload,
					});

					var config = {
						method: 'post',
						url: process.env
							.VUE_APP_MICRO_LESSON_API,
						headers: {
							Authorization: `Bearer ${process.env.VUE_APP_EVENT_TOKEN}`,
							'Content-Type':
								'application/json',
						},
						data: data,
					};

					axios(config)
						.then(function (
							response
						) {
							console.log(
								JSON.stringify(
									response.data
								)
							);
						})
						.catch(function (error) {
							console.log(error);
						});

					// var url =
					// 	'https://uat-events.tinkerpub.com/api/v1/events';

					// console.log(params, 'params');

					// axios
					// 	.post(url, data, {
					// 		headers: {
					// 			Authorization:
					// 				`Bearer ` +
					// 				params.token,
					// 			'Content-Type':
					// 				'application/json',
					// 			'X-Requested-With':
					// 				'XMLHttpRequest',
					// 			Accept: 'application/json',
					// 		},
					// 	})
					// 	.then((response) => {})
					// 	.catch((error) => {
					// 		console.log(error);
					// 	});
				}
			);
		},
		async updateData(
			{ commit, state },
			value
		) {
			commit('UPDATE_MICROLESSON_BARS');
		},
		async updateQuestions(
			{ commit, state },
			value
		) {
			let data = state.micro_lessons;
			data[value]['answered'] = true;
			commit('UPDATE_MICROLESSONS', data);
		},
		async getData({ commit, state }, value) {
			return new Promise(
				(resolve, reject) => {
					const config = {
						headers: {
							Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
							'Content-Type':
								'application/json',
							'X-Requested-With':
								'XMLHttpRequest',
							Accept: 'application/json',
						},
					};
					var token =
						window.location.hash.split(
							'?token='
						)[1];

					var url =
						process.env
							.VUE_APP_API_URL +
						'/api/dashboard/data?token=' +
						token;
					axios
						.get(url, config)
						.then((response) => {
							if (
								$cookies.get(
									'customer_id'
								)
							) {
								if (
									$cookies.get(
										'customer_id'
									) !==
									response.data
										.customer_id
								) {
									$cookies.remove(
										'micro_lessons'
									);
									$cookies.remove(
										'customer_id'
									);
									$cookies.remove(
										'answered'
									);
								}
							}
							console.log(
								response.data
							);

							commit(
								'GET_MICROLESSONS',
								response.data
									.micro_lessons
							);

							commit(
								'GET_DATA',
								response.data
							);
							resolve(response);
						})
						.catch((error) => {
							console.log(error);
						});
				}
			);
		},

		// retention
	},
	getters: {
		_micro_lessons: (state) =>
			state.micro_lessons,
		_data_option: (state) =>
			state.data_option,
	},
});

export default store;
